import React from 'react';

const CareersFlexibility = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.0835 23.6658C24.9411 23.5236 24.8595 23.3315 24.8557 23.13C24.8518 22.9285 24.9261 22.7332 25.063 22.5849L31.001 16.6469C31.1475 16.5004 31.346 16.4181 31.5528 16.4178C31.7596 16.4175 31.9579 16.4993 32.104 16.6453L33.5135 18.0549L35.0716 8.25894L25.2751 9.81649L26.6631 11.2045C26.8092 11.3506 26.891 11.5488 26.8907 11.7557C26.8904 11.9625 26.8081 12.161 26.6616 12.3075L20.7236 18.2455C20.5771 18.392 20.3786 18.4743 20.1718 18.4746C19.9649 18.4749 19.7667 18.3931 19.6206 18.2471C19.4746 18.101 19.3928 17.9027 19.393 17.6959C19.3933 17.4891 19.4757 17.2906 19.6222 17.1441L25.0079 11.7583L23.0913 9.84165C22.987 9.74121 22.912 9.61508 22.8734 9.47547C22.8348 9.33587 22.8341 9.18792 22.8718 9.04787C22.9156 8.90767 22.993 8.7804 23.0969 8.67649C23.2009 8.57258 23.3281 8.49512 23.4683 8.45139L35.8243 6.49306C35.9467 6.47777 36.0706 6.49031 36.1872 6.53025C36.3038 6.57019 36.4095 6.63608 36.4966 6.72315C36.5837 6.81023 36.6501 6.91653 36.69 7.03308C36.73 7.14964 36.7425 7.2736 36.7272 7.39601L34.7689 19.752C34.7252 19.8922 34.6477 20.0194 34.5438 20.1233C34.4399 20.2272 34.3126 20.3047 34.1724 20.3484C34.0377 20.388 33.8946 20.3902 33.7588 20.3555C33.623 20.3207 33.4995 20.2504 33.4007 20.1511L31.462 18.2123L26.0762 23.5981C25.9441 23.7232 25.7727 23.7984 25.5915 23.8107C25.4102 23.8231 25.2307 23.7718 25.0835 23.6658Z"
      fill="#475467"
    />
    <path
      d="M17.6658 30.9195C17.5236 30.777 17.3315 30.6954 17.13 30.6916C16.9285 30.6878 16.7332 30.762 16.5849 30.8989L10.6469 36.8369C10.5004 36.9834 10.4181 37.1819 10.4178 37.3887C10.4175 37.5956 10.4993 37.7938 10.6453 37.9399L12.0549 39.3494L2.25894 40.9075L3.81649 31.111L5.20452 32.4991C5.35058 32.6451 5.54883 32.7269 5.75567 32.7266C5.96252 32.7264 6.161 32.644 6.30747 32.4975L12.2455 26.5595C12.392 26.413 12.4743 26.2145 12.4746 26.0077C12.4749 25.8009 12.3931 25.6026 12.2471 25.4565C12.101 25.3105 11.9027 25.2287 11.6959 25.229C11.4891 25.2293 11.2906 25.3116 11.1441 25.4581L5.75833 30.8439L3.84165 28.9272C3.74121 28.823 3.61508 28.7479 3.47547 28.7093C3.33587 28.6707 3.18792 28.67 3.04787 28.7078C2.90767 28.7515 2.7804 28.829 2.67649 28.9329C2.57258 29.0368 2.49512 29.1641 2.45139 29.3043L0.493064 41.6602C0.477773 41.7826 0.490309 41.9066 0.53025 42.0231C0.570192 42.1397 0.636077 42.2455 0.723153 42.3325C0.810229 42.4196 0.916526 42.486 1.03308 42.526C1.14964 42.5659 1.2736 42.5784 1.39601 42.5632L13.752 40.6048C13.8922 40.5611 14.0194 40.4836 14.1233 40.3797C14.2272 40.2758 14.3047 40.1485 14.3484 40.0084C14.388 39.8736 14.3902 39.7306 14.3555 39.5947C14.3207 39.4589 14.2504 39.3354 14.1511 39.2366L12.2123 37.2979L17.5981 31.9121C17.7232 31.7801 17.7984 31.6086 17.8107 31.4274C17.8231 31.2462 17.7718 31.0666 17.6658 30.9195Z"
      fill="#475467"
    />
    <path
      d="M19.4514 30.9195C19.5936 30.777 19.7857 30.6954 19.9872 30.6916C20.1887 30.6878 20.384 30.762 20.5323 30.8989L26.4703 36.8369C26.6168 36.9834 26.6991 37.1819 26.6994 37.3887C26.6997 37.5956 26.6179 37.7938 26.4719 37.9399L25.0623 39.3494L34.8582 40.9075L33.3007 31.111L31.9127 32.4991C31.7666 32.6451 31.5684 32.7269 31.3615 32.7266C31.1547 32.7264 30.9562 32.644 30.8097 32.4975L24.8717 26.5595C24.7252 26.413 24.6429 26.2145 24.6426 26.0077C24.6423 25.8009 24.7241 25.6026 24.8701 25.4565C25.0162 25.3105 25.2144 25.2287 25.4213 25.229C25.6281 25.2293 25.8266 25.3116 25.9731 25.4581L31.3589 30.8439L33.2755 28.9272C33.376 28.823 33.5021 28.7479 33.6417 28.7093C33.7813 28.6707 33.9293 28.67 34.0693 28.7078C34.2095 28.7515 34.3368 28.829 34.4407 28.9329C34.5446 29.0368 34.6221 29.1641 34.6658 29.3043L36.6241 41.6602C36.6394 41.7826 36.6269 41.9066 36.5869 42.0231C36.547 42.1397 36.4811 42.2455 36.394 42.3325C36.307 42.4196 36.2007 42.486 36.0841 42.526C35.9675 42.5659 35.8436 42.5784 35.7212 42.5632L23.3652 40.6048C23.225 40.5611 23.0978 40.4836 22.9939 40.3797C22.8899 40.2758 22.8125 40.1485 22.7688 40.0084C22.7292 39.8736 22.727 39.7306 22.7617 39.5947C22.7965 39.4589 22.8668 39.3354 22.9661 39.2366L24.9049 37.2979L19.5191 31.9121C19.394 31.7801 19.3188 31.6086 19.3065 31.4274C19.2941 31.2462 19.3454 31.0666 19.4514 30.9195Z"
      fill="#475467"
    />
    <path
      d="M12.4633 23.9314C12.6058 23.7892 12.6874 23.5971 12.6912 23.3956C12.695 23.1941 12.6208 22.9988 12.4839 22.8505L6.54586 16.9125C6.3994 16.766 6.20091 16.6837 5.99407 16.6834C5.78723 16.6831 5.58897 16.7649 5.44292 16.911L4.03337 18.3205L2.47529 8.52457L12.2718 10.0821L10.8837 11.4701C10.7377 11.6162 10.6559 11.8145 10.6562 12.0213C10.6565 12.2281 10.7388 12.4266 10.8853 12.5731L16.8233 18.5111C16.9698 18.6576 17.1683 18.74 17.3751 18.7402C17.582 18.7405 17.7802 18.6587 17.9263 18.5127C18.0723 18.3666 18.1541 18.1684 18.1538 17.9615C18.1535 17.7547 18.0712 17.5562 17.9247 17.4097L12.5389 12.024L14.4556 10.1073C14.5598 10.0068 14.6349 9.8807 14.6735 9.7411C14.7121 9.60149 14.7128 9.45355 14.675 9.31349C14.6313 9.1733 14.5538 9.04602 14.4499 8.94212C14.346 8.83821 14.2187 8.76075 14.0786 8.71702L1.72261 6.75869C1.6002 6.7434 1.47623 6.75593 1.35968 6.79588C1.24312 6.83582 1.13736 6.9017 1.05028 6.98878C0.963209 7.07585 0.896786 7.18215 0.856844 7.29871C0.816903 7.41527 0.804367 7.53923 0.819658 7.66164L2.77799 20.0176C2.82171 20.1578 2.89918 20.2851 3.00309 20.389C3.10699 20.4929 3.23426 20.5703 3.37446 20.6141C3.50916 20.6537 3.65225 20.6559 3.78808 20.6211C3.9239 20.5864 4.04739 20.516 4.14619 20.4167L6.08492 18.478L11.4707 23.8637C11.6027 23.9888 11.7742 24.064 11.9554 24.0763C12.1367 24.0887 12.3162 24.0375 12.4633 23.9314Z"
      fill="#475467"
    />
  </svg>
);

export default CareersFlexibility;
